
// Content styles
.centre .content {

	ul,ol {
		ul {
			li {
				list-style: disc;
				margin-left: $block-margin;
				padding-left: $block-padding / 2;
			}
		}
	}

	a {
		font-weight: $font-bold;
	}

	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&strong {
			font-weight: $font-bold;
		}

		&.intro {
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h4;

			@media #{$mobileXL} {
				font-size: $h3;
				line-height: 1.5;
			}
		}
	}

	#profile &{

		p {
			margin: 0 0 $paragraph-margin;

			&.intro {

				@media #{$mobileXL} {
					font-size: $h3;
					line-height: 1.5;
				}
			}
		}

	}

	// New default <ul> lists
	* + ul {
		@extend %default-ul;
	}

	// New default <ol> lists
	* + ol {
		@extend %default-ol;
	}
}

