
//==========  Import modules  ==========//

// import our global modules
@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

// import our local modules
@import '../modules/local/typography/_index';


//==========  Begin styles  ==========//

// Helper class
.hidden {
	display: none;
}


// Reset
.cms-toolbar {
	@extend .hidden;
}


// Print styles
* {
	color: $color-black;
	background: none;
}

.wrapper {
	padding-bottom: 0 !important;
	padding: 2rem;
}

.header {
	

	.inner {
		* {
			@extend .hidden;
		}

		.company-logo-link,
		.company-logo {
			display: block;
		}

		.company-logo {
			width: 100px;
			margin: 0 auto 3rem auto;
		}
	}

}

.hero {

	
	.inner {

		* {
			@extend .hidden;
		}
	}
}

#profile {

	.hero {
		.hero-inner {
			.profile-image-wrapper,
			.profile-image {
				display: block;
			}

			.profile-image-wrapper {
				margin-bottom: 3rem;
			}

			.profile-image {
				max-width: 500px;
			}
		}
	}



	.column {

		&.right {
			@extend .hidden;
		}
	}

	#container-left {
		display: block;

		.profile-contact,
		.profile-personal {
			margin-bottom: $block-margin;
		}

		.profile-contact {

			.profile-contact-details {
				li {

					&.phone {
			
						&:before {
							content: "T:\00a0";
						}
					}
			
					&.fax {
			
						&:before {
							content: "F:\00a0";
						}
					}
			
					&.dx {
			
						&:before {
							content: "DX:\00a0";
						}
					}
				}
			}

			.profile-contact-links {
				display: none;
			}
		}

		.profile-personal {
			p {
				margin-bottom: $paragraph-margin;
			}
		}
	}

	.recent-cases-tabs,
	.case-header,
	.tabs-container {

		@extend .hidden;
	}
}

.column {

	&.right {
		@extend .hidden;
	}
}

.pre-footer {
	@extend .hidden;
}

.search-container {
	@extend .hidden;
}

.social-icons,
.footer-links {
	@extend .hidden;
}

.cookies {
	@extend .hidden;
}

.header-links,
#mobile-ui,
.pre-footer,
.mobile-ui-buttons,
.mobile-ui-panels-container,
#staff-rotator-component,
#container-left {

	@extend .hidden;

}


//profile

.case-list {

	margin-bottom:$block-padding;

}

.staff-profile {

	.barrister {

		@extend .hidden;
	}
}

#ccc-notify {
	display: none!important;
}
