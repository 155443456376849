//==========  Extends  ==========//

// use these classes and placeholders with @extends
// classes defined here will be output in the final compiled css
// placeholders will not and are only used within sass
 
// note - extends will not work within media queries


//==========  Helpers and resets  ==========//

// set on .inner containers
%full-width { 
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
}

// clear floats on elements after container
// prevents elements after from wrapping
%clear-fix {
    
    &:after {
        content: '';
        clear: both;
        display: block;
        width: 100%;
        overflow: hidden;
    }
}

// svg icon reset - use to hide any icons
%no-icon {
    min-height: 0;

    .icon-link {
        min-height: 0;
        padding-left: 0;
    }

    .icon {
        display: none;
    }
}

// reset for images, prevents whitespace around image
%image {
    display: block;
    width: 100%;
}

// default style for blockquotes
%blockquote {
    margin: 0;
    
    // left quote marks
    &:before {
        content: '\201C';
    }
    
    // right quote marks
    &:after {
        content: '\201D';
    }
    
    // reset for p tags that are put in by the CMS editor
    p {
        display: inline;
        margin: 0;
    }
}

%font-action {
    font-family: $font-family-body;
    font-size: 0.9rem;
    font-weight: $font-ultra-bold;
    // text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

// %font-transform {
//     font-size: $font-size-nav;
//     font-family:$font-family-nav;
//     text-transform: uppercase;
//     font-weight:$font-ultra-bold;
//     letter-spacing: 0.2em;
// }

%arrow {
    display: inline-block;
    font-size: 1.5rem;
    transform: translateY(0.15em); // 10%
    font-weight: $font-normal;
}

%after-arrow-up {
    &:after {
        content: "\00a0\2191";
        @extend %arrow;
    }
}

%after-arrow-right {
    &:after {
        content: "\00a0\2192";
        @extend %arrow;
    }
}

%after-arrow-down {
    &:after {
        content: "\00a0\2193";
        @extend %arrow;
    }
}

%after-arrow-left {
    &:after {
        content: "\00a0\2190";
        @extend %arrow;
    }
}

%before-arrow-up {
    &:before {
        content: "\2191\00a0";
        @extend %arrow;
    }
}

%before-arrow-right {
    &:before {
        content: "\2192\00a0";
        @extend %arrow;
    }
}

%before-arrow-down {
    &:before {
        content: "\2193\00a0";
        @extend %arrow;
    }
}

%before-arrow-left {
    &:before {
        content: "\2190\00a0";
        @extend %arrow;
    }
}


// add as class to element or extend
.hidden {
    display: none;
}



%testimonial {

    blockquote {

        border-left:3px solid $color-primary;
        margin:0;
        position:relative;

        p {

            font-family: $font-family-title;
            font-size: $h2;
            line-height: 1.2;
            color: $color-sub;
            padding-left: $block-padding * 1.2;
            padding-bottom: $block-padding * 1.2;
            
        }

        cite {

            font-size: $font-size-nav;
            font-family:$font-family-nav;
            text-transform: uppercase;
            font-weight:$font-ultra-bold;
            letter-spacing: 0.2em;
            color: $color-sub;
            font-style: normal;
            padding-left: calc( 45px  + (#{$block-padding} * 1.2));

        }

        &:after {

            content:'\201C';
            font-family: serif;
            background:$color-primary;
            color:$color-white;
            font-size:4.5rem;
            line-height:45px;
            height:45px;
            width:45px;
            padding:1rem 1rem 1rem 0.3rem;
            letter-spacing: -4px;
            position:absolute;
            bottom:0;
            left:-1px;
        }

    }


    .view-testimonials {

        margin-top: $block-margin;

        a {

            font-size: $font-size-nav;
            font-family:$font-family-nav;
            text-transform: uppercase;
            font-weight:$font-ultra-bold;
            letter-spacing: 0.2em;
            color:$color-secondary;

            &:after {
                content: '\2192';
                display: inline-block;
                position: relative;
                right:-$block-padding/3;
                transition: all 0.2s ease-out;
            }


            &:hover {

                color:$color-primary;

                &:after {

                    color:$color-primary;
                    
                }
            }

        }
    }

}
