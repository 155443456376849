html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

.hidden, .cms-toolbar, .header .inner *, .hero .inner *, #profile .column.right, #profile .recent-cases-tabs,
#profile .case-header,
#profile .tabs-container, .column.right, .pre-footer, .search-container, .social-icons,
.footer-links, .cookies, .header-links,
#mobile-ui,
.mobile-ui-buttons,
.mobile-ui-panels-container,
#staff-rotator-component,
#container-left, .staff-profile .barrister {
  display: none; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

.centre .content * + ul, .centre .content * + ol {
  margin: 0 0 2rem;
  padding: 0 0 0 1.5rem; }

.centre .content * + ul {
  list-style: disc; }

.centre .content * + ol {
  list-style: decimal; }

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0; }

.button {
  border: none;
  border-radius: 4px;
  background: #0d755a;
  color: #fff;
  text-align: center; }

.button:hover {
  background: #e4b821;
  color: #fff; }

html {
  font-size: 95%; }
  @media only screen and (min-width: 40em) {
    html {
      font-size: 100%; } }
  @media only screen and (min-width: 60em) {
    html {
      font-size: 105%; } }

body {
  color: #203630;
  font-family: "Maven Pro", sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.7; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 2rem;
  color: #203630;
  font-family: "Playfair Display", "Georgia", "Arial", serif;
  font-weight: 300;
  line-height: 1.3; }

h1, .h1 {
  font-size: 3rem; }

h2, .h2 {
  font-size: 2.2rem; }

h3, .h3 {
  font-size: 1.8rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.3rem; }

h6, .h6 {
  font-size: 1.2rem; }

p {
  margin: 0; }

strong {
  font-weight: 800; }

hr {
  border: 1px solid #f6f6f6; }

a {
  color: #0d755a;
  text-decoration: none;
  transition: all 0.2s ease-out; }
  a:hover {
    color: #e4b821; }

.column .table-scroll {
  overflow: scroll;
  margin: 0 0 2rem; }
  .column .table-scroll table {
    width: 100%; }
    .column .table-scroll table th {
      padding: 1rem 1.5rem;
      border: 2px solid #0a5e48;
      background: #0d755a;
      color: #fff;
      font-family: "Playfair Display", "Georgia", "Arial", serif; }
    .column .table-scroll table td {
      padding: 1.5rem;
      border: 2px solid #f6f6f6;
      vertical-align: top; }
    .column .table-scroll table p {
      margin: 0; }

.centre .content ul ul li, .centre .content ol ul li {
  list-style: disc;
  margin-left: 3rem;
  padding-left: 0.75rem; }

.centre .content a {
  font-weight: 800; }

.centre .content p {
  margin: 0 0 2rem; }
  .centre .content pstrong {
    font-weight: 800; }
  .centre .content p.intro {
    color: #939897;
    font-family: "Playfair Display", "Georgia", "Arial", serif;
    font-size: 1.5rem; }
    @media only screen and (min-width: 40em) {
      .centre .content p.intro {
        font-size: 1.8rem;
        line-height: 1.5; } }

#profile .centre .content p {
  margin: 0 0 2rem; }
  @media only screen and (min-width: 40em) {
    #profile .centre .content p.intro {
      font-size: 1.8rem;
      line-height: 1.5; } }

.button {
  display: inline-block;
  padding: 0.5rem 3rem;
  border-radius: 4px;
  transition: all 0.3s ease-out; }
  .button a {
    color: inherit;
    transition: inherit; }

.hidden, .cms-toolbar, .header .inner *, .hero .inner *, #profile .column.right, #profile .recent-cases-tabs,
#profile .case-header,
#profile .tabs-container, .column.right, .pre-footer, .search-container, .social-icons,
.footer-links, .cookies, .header-links,
#mobile-ui,
.mobile-ui-buttons,
.mobile-ui-panels-container,
#staff-rotator-component,
#container-left, .staff-profile .barrister {
  display: none; }

* {
  color: #111;
  background: none; }

.wrapper {
  padding-bottom: 0 !important;
  padding: 2rem; }

.header .inner .company-logo-link,
.header .inner .company-logo {
  display: block; }

.header .inner .company-logo {
  width: 100px;
  margin: 0 auto 3rem auto; }

#profile .hero .hero-inner .profile-image-wrapper,
#profile .hero .hero-inner .profile-image {
  display: block; }

#profile .hero .hero-inner .profile-image-wrapper {
  margin-bottom: 3rem; }

#profile .hero .hero-inner .profile-image {
  max-width: 500px; }

#profile #container-left {
  display: block; }
  #profile #container-left .profile-contact,
  #profile #container-left .profile-personal {
    margin-bottom: 3rem; }
  #profile #container-left .profile-contact .profile-contact-details li.phone:before {
    content: "T:\00a0"; }
  #profile #container-left .profile-contact .profile-contact-details li.fax:before {
    content: "F:\00a0"; }
  #profile #container-left .profile-contact .profile-contact-details li.dx:before {
    content: "DX:\00a0"; }
  #profile #container-left .profile-contact .profile-contact-links {
    display: none; }
  #profile #container-left .profile-personal p {
    margin-bottom: 2rem; }

.case-list {
  margin-bottom: 1.5rem; }

#ccc-notify {
  display: none !important; }
